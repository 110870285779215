import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  min-height: 56.8rem;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  /* justify-items: center; */
`

export const Content = styled.div`
  position: relative;
  z-index: 10;
  max-width: 68rem;
  align-self: center;
  justify-self: center;
`
