import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Item } from "./sponsors.styles"

export const Sponsor = ({ link, image }) => {
  return (
    <Item mx="x.2" href={link.url} rel="noopener" target="_blank">
      <GatsbyImage image={getImage(image)} alt={image.alt ?? ""} />
    </Item>
  )
}

export default ({ sponsors }) => {
  // width smaller than viewport
  // duplicate sponsors
  // sponsor.1 translateX()
  // sponsor.1 set 100% ()
  return (
    <Container py={["y.mobile.0.5", "y.desktop.0.5"]}>
      {sponsors.map((sponsor, index) => (
        <Sponsor key={`sponsor-${index}`} {...sponsor} />
      ))}
    </Container>
  )
}
