import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash.get"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/workshop-hero"
import Slices from "../components/slices"
import Grid from "../components/grid"
import Breadcrumbs from "../components/breadcrumbs"
import SubNav from "../components/sub-nav"

export const query = graphql`
  query Workshop {
    site {
      siteMetadata {
        title
      }
    }
    prismicWorkshop {
      id
      uid
      type
      data {
        name
        title {
          richText
          text
        }
        subtitle {
          richText
          text
        }
        sub_pages {
          sub_page {
            document {
              ... on PrismicSubPage {
                id
                uid
                type
                data {
                  name
                }
              }
            }
          }
        }
        image {
          alt
          copyright
          gatsbyImageData(layout: FULL_WIDTH)
        }
        sponsors {
          link {
            link_type
            url
          }
          image {
            alt
            copyright
            gatsbyImageData(layout: FIXED, height: 84)
          }
        }
        body {
          ... on PrismicWorkshopDataBodyText {
            id
            slice_type
            slice_label
            primary {
              text {
                text
                richText
              }
            }
          }
          ... on PrismicWorkshopDataBodyImage {
            id
            slice_type
            slice_label
            primary {
              caption {
                text
                richText
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 856)
              }
            }
          }
          ... on PrismicWorkshopDataBodyGallery {
            id
            slice_label
            slice_type
            items {
              caption {
                text
                richText
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 1024)
              }
            }
          }
        }
      }
    }
  }
`

export default function WorkshopPage({ location, data }) {
  const page = get(data, `prismicWorkshop.data`)
  if (!page) return null

  return (
    <Layout location={location} variant="dark">
      <SEO
        title={page.name}
        description={page.subtitle.text}
        image={page.image}
      />
      <Hero {...page} />
      <Grid page>
        <Breadcrumbs>
          <Link to="/">Home</Link>
          <span>{page.name}</span>
        </Breadcrumbs>
        <SubNav path="/workshops" pages={page.sub_pages} />
        <Slices slices={page.body} page />
      </Grid>
    </Layout>
  )
}
