import styled, { keyframes } from "styled-components"
import { space, flexbox } from "styled-system"

// https://codepen.io/danedmonds/pen/grEYwA
const move = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`

export const Container = styled.div`
  ${flexbox}
  ${space}
  background: rgba(0,0,0,0.5);
  position: relative;
  z-index: 10;
  display: flex;
  flex-flow: row;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`
Container.defaultProps = {
  justifyContent: [`flex-start`, `flex-start`, `center`],
}

export const Item = styled.a`
  ${space}
  height: 8.4rem;
`
