import React from "react"
import { PrismicText } from "@prismicio/react"

import { Container, Content } from "./workshop-hero.styles"
import BackgroundImage from "./background-image"
import ScrollTo from "./scroll-to"
import Sponsors from "./sponsors"
import Text from "./text"

export default ({ title, subtitle, image, sponsors }) => {
  return (
    <Container>
      <Content>
        <Text.H1
          textAlign="center"
          mb={["y.mobile.0.5", "y.desktop.0.5"]}
          variant="dark"
        >
          <PrismicText field={title.richText} />
        </Text.H1>
        <Text.P
          textAlign="center"
          variant="dark"
          lineHeight={["mobile.1.5", "desktop.1.5"]}
        >
          {subtitle.richText.map(({ text }, index) => (
            <React.Fragment key={`subtitle-${index}`}>
              {text}
              <br />
            </React.Fragment>
          ))}
        </Text.P>
      </Content>
      <BackgroundImage image={image} />
      <ScrollTo bottom={["calc(7.2rem + 11.2rem)", "calc(8.4rem + 11.2rem)"]} />
      <Sponsors sponsors={sponsors} />
    </Container>
  )
}
